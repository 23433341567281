import React from 'react';
import { useSelector } from 'react-redux';

import { TimelineItem } from './TimelineItem';
import { getTimelineSteps } from './helpers';

export const TimelineSteps = ({ timeline }) => {
  const { investor } = useSelector(state => state.profile) || {};

  return (
    <>
      {getTimelineSteps(
        timeline,
        investor?.kycRejectionReason?.description,
      ).map((step, index) => (
        <TimelineItem key={index} {...step} />
      ))}
    </>
  );
};
