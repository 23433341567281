export const actionTypes = {
  UpdateSubscriptionPaymentDetails: '[UpdateSubscriptionPaymentDetails] Action',
  UpdateAccountPlansList: '[UpdateAccountPlansList] Action',
};

const initialAuthState = {};

const accountPlansList = [];

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case actionTypes.UpdateSubscriptionPaymentDetails: {
      const subscriptionPaymentDetails = action.payload;
      return subscriptionPaymentDetails;
    }

    default:
      return state;
  }
};

export const updateAccountPlansListReducer = (
  state = accountPlansList,
  action,
) => {
  switch (action.type) {
    case actionTypes.UpdateAccountPlansList: {
      const accountPlansList = action.payload;
      return [...accountPlansList];
    }

    default:
      return state;
  }
};

export const actions = {
  updateAccountPlansList: accountPlansList => ({
    type: actionTypes.UpdateAccountPlansList,
    payload: accountPlansList,
  }),

  updateSubscriptionPaymentDetails: subscriptionPaymentDetails => ({
    type: actionTypes.UpdateSubscriptionPaymentDetails,
    payload: subscriptionPaymentDetails,
  }),
};

export function* saga() {}
