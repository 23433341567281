import React from 'react';
import Countdown, { zeroPad } from 'react-countdown';
import Alert from '../../../Common/Alert';
import './index.scss';
import { CardWithHeader } from '../../../Common/CardWithHeader';
import { PhoneNumberInput } from '../../../Common/PhoneNumberInput';
import { PayNow } from '../../../Subscriptions/components/PayNow';
import { moneyFormat } from '../../../../helpers/moneyFormat';
import { CancelButton, ConfirmButton } from '../../../Common/ReusableButtons';
import { ButtonsContainer } from '../../../Common/ButtonsContainer';

const PhoneNumber = ({
  setPayWithMobileMoney,
  formik,
  amount,
  currency,
  validPhoneNumber,
  country,
  showCountDown,
  loading,
  alertOn,
  alert,
  setOpen,
}) => {
  return (
    <CardWithHeader cardHeader={`Enter your phone number`}>
      <div className="px-5">
        {alertOn && (
          <div className="my-6">
            <Alert alert={alert} />
          </div>
        )}

        <div className="mobile-money-payment-page-body-container text-center mx-auto mt-6">
          <PayNow currency={currency} amount={moneyFormat(amount)} />
          <form id="kt_form" className="mt-8" onSubmit={formik.handleSubmit}>
            <PhoneNumberInput
              formik={formik}
              validPhoneNumber={validPhoneNumber}
              selectedCountry={country}
              currency={currency}
              setOpen={setOpen}
            />

            {showCountDown && (
              <div className="mt-2 text-center text-11">
                Please wait for{'  '}
                <Countdown
                  date={Date.now() + 180000}
                  renderer={({ minutes, seconds, completed }) =>
                    !completed && (
                      <span className="font-weight-600">
                        {zeroPad(minutes)}:{zeroPad(seconds)}
                      </span>
                    )
                  }
                />
                {'  '}
                while your payment is being processed.
              </div>
            )}
            <div className="mb-8">
              <ButtonsContainer>
                <CancelButton
                  disable={loading}
                  buttonText={'Cancel'}
                  handleOnClick={() => setPayWithMobileMoney(false)}
                  buttonWidth={'width-220'}
                />

                <ConfirmButton
                  buttonText={'Pay Now'}
                  buttonType={'submit'}
                  disable={loading}
                  loading={loading}
                  buttonWidth={'width-220'}
                />
              </ButtonsContainer>
            </div>
          </form>
        </div>
      </div>
    </CardWithHeader>
  );
};

export default PhoneNumber;
