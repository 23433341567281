import React from 'react';
import SVG from 'react-inlinesvg';
import { messages } from '../../utils/messages';
import PopoverComponent from './PopoverComponent';

const PopoverIcon = ({ message, iconName }) => {
  const content = (
    <div>{message ? message : messages.pendingTransactions.totalInvested}</div>
  );

  return (
    <PopoverComponent popoverContent={content}>
      <span
        style={{ marginTop: '-4px', marginLeft: '5px', paddingTop: '5px' }}
        className="cursor-pointer"
      >
        {iconName ? (
          <SVG src={`/media/svg/ndovu/${iconName}.svg`} />
        ) : (
          <i
            style={{ color: '#0071CE' }}
            className="fa fa-exclamation-circle"
            aria-hidden="true"
          ></i>
        )}
      </span>
    </PopoverComponent>
  );
};

export default PopoverIcon;
