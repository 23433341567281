import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  Currency,
  mobileMoneyCurrencies,
  paymentMethod,
  PaymentType,
} from '../../helpers/constants';
import Alert from '../Common/Alert';
import BackArrow from '../Common/BackArrow';
import { getCardPaymentModal as subscribeToBlack } from '../../helpers/cardPaymentModal';
import PayWithMobileMoney from '../Invest/components/PayWithMobileMoney';
import { moneyFormat } from '../../helpers/moneyFormat';
import { Button } from './components/Button';
import { CardWithHeader } from '../Common/CardWithHeader';
import { PayNow } from './components/PayNow';
import SelectOptionsModal from '../Common/SelectOptionsModal';
import getCountries from '../../helpers/countries';
import { AnimatedPageContainer } from '../Common/AnimatedPageContainer';
import { getCountryDetailsPerCurrency } from '../../helpers/getCountryDetails';

const Subscribe = () => {
  let history = useHistory();
  const [alertOn, setAlertOn] = useState(false);
  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });
  const [loading, setLoading] = useState();
  const [payWithMobileMoney, setPayWithMobileMoney] = useState(false);
  const [open, setOpen] = useState(false);
  const [allCountries, setAllCountries] = useState();
  const [selectedCountry, setSelectedCountry] = useState();

  const accountPlanDetails = useSelector(state => state.subscription);

  const accountPlanPrice = accountPlanDetails?.price;

  useEffect(() => {
    const countries = getCountries();
    setAllCountries(countries);
  }, []);

  const currencyIsNotSupported = () => {
    if (accountPlanPrice?.currency !== Currency.KES) {
      setAlertOn(true);
      setAlert({
        alertMessage:
          'Your default currency is not supported to upgrade subscription. Please, contact our customer service.',
        alertMessageType: 'error',
      });
    }
  };

  useEffect(() => {
    setSelectedCountry(
      getCountryDetailsPerCurrency(accountPlanPrice?.currency),
    );
    currencyIsNotSupported();
  }, []);

  const handleSelect = country => {
    setSelectedCountry(country);
    setOpen(false);
  };

  const handleSubscribe = async () => {
    setLoading(true);
    try {
      const response = await subscribeToBlack(
        accountPlanPrice?.amount,
        accountPlanPrice?.currency,
        null,
        paymentMethod.CARD,
        PaymentType.SUBSCRIPTION_PAYMENT,
      );

      const { data: { data: { link } = {} } = {} } = response;
      localStorage.setItem(
        'action',
        JSON.stringify({
          name: 'subscribe',
          id: null,
        }),
      );
      window.location.replace(link);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setAlertOn(true);
      setAlert({
        alertMessage: error?.response?.data?.message,
        alertMessageType: 'error',
      });
      setTimeout(() => {
        setAlertOn(!setAlertOn);
      }, 3000);
    }
  };

  return (
    <AnimatedPageContainer>
      <BackArrow
        text={'Back'}
        onClick={() =>
          payWithMobileMoney
            ? setPayWithMobileMoney(false)
            : history.push('/subscription')
        }
      />
      {payWithMobileMoney ? (
        <PayWithMobileMoney
          setPayWithMobileMoney={setPayWithMobileMoney}
          amount={accountPlanPrice?.amount}
          currency={accountPlanPrice?.currency}
          setOpen={() => {}}
          selectedCountry={selectedCountry}
        />
      ) : (
        <>
          <CardWithHeader cardHeader={`Upgrade to ${accountPlanDetails?.name}`}>
            <div className="px-5">
              <div className="text-center mt-6">
                {alertOn ? <Alert alert={alert} /> : null}
              </div>

              <div className="text-14 text-center mt-6">
                {accountPlanPrice?.currency === Currency.KES ? (
                  <PayNow
                    currency={accountPlanPrice?.currency}
                    amount={moneyFormat(accountPlanPrice?.amount)}
                  />
                ) : null}

                {/* {mobileMoneyCurrencies?.includes(accountPlanPrice?.currency) ? (
                  <div className="mt-8">Select Payment method</div>
                ) : null} */}
              </div>

              <div className="my-8">
                {accountPlanPrice?.currency === Currency.KES ? (
                  <Button
                    buttonText={'Pay with Mobile Money'}
                    handleButtonClick={() => setPayWithMobileMoney(true)}
                  />
                ) : null}

                {/* <Button
                  buttonText={'Pay with Credit Card'}
                  handleButtonClick={handleSubscribe}
                  loading={loading}
                  disabled={true}
                /> */}
              </div>
            </div>
          </CardWithHeader>
        </>
      )}

      <SelectOptionsModal
        open={open}
        handleClose={() => setOpen(false)}
        allCountries={allCountries}
        handleSelect={handleSelect}
        selectedCountry={selectedCountry}
      />
    </AnimatedPageContainer>
  );
};

export default Subscribe;
