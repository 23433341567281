import React from 'react';
import SVG from 'react-inlinesvg';

import { currencyHelper } from '../../../helpers/currencyHelper';
import { useHistory } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';

const PortfolioAssetCard = ({ id: planId, plan, investmentDetails }) => {
  const history = useHistory();
  const { margin, fundPortfolioValue, totalPendingAmount } =
    investmentDetails || {};

  return (
    <div
      className="portfolio-asset-card justify-content-between border-light-blue-600 border-radius-6 cursor-pointer"
      onClick={() => history.push(`/portfolio/viewInvestment/${planId}`)}
    >
      <div
        className="portfolio-asset-image"
        style={{
          backgroundImage: `url(${plan.thumbnail})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
      ></div>

      <div className="px-4 portfolio-asset-content">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center text-13">
            <span className="text-dark-gray-300">Profit:</span>{' '}
            <span
              className={`${
                margin < 0 ? 'text-second-orange' : 'text-second-green'
              } ml-1`}
            >
              {margin && margin?.toFixed(2)}%
            </span>
            <span>
              <SVG
                src={toAbsoluteUrl(
                  `/media/svg/ndovu/polygon-${
                    margin && margin < 0 ? 'red' : 'green'
                  }.svg`,
                )}
              />
            </span>
          </div>

          {totalPendingAmount ? (
            <div className="d-flex flex-column text-right text-dark-550 text-9 font-weight-500">
              <div>{currencyHelper(totalPendingAmount, plan?.currency)}</div>
              <div>(Pending)</div>
            </div>
          ) : null}
        </div>

        <div className="d-flex justify-content-between align-items-center">
          <div className="text-14 font-weight-600">{plan?.coolName}</div>

          <div className="text-dark-550 text-10 font-weight-600 current-value-amount text-right">
            {fundPortfolioValue || fundPortfolioValue === 0
              ? currencyHelper(fundPortfolioValue, plan?.currency)
              : '---'}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PortfolioAssetCard;
