import './selector.scss';

import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import Exit from './Exit';
import { CancelButton, ConfirmButton } from '../../Common/ReusableButtons';
import { cancelWizard } from '../helpers/cancelWizard';

const Selector = ({
  clickChoice,
  choices,
  id,
  item,
  next,
  prev,
  action,
  subAction,
}) => {
  const history = useHistory();
  const isRetakingWizard = localStorage.getItem('retakingTheWizard');

  return (
    <div
      className="wizard wizard-1 animate__animated animate__fadeInUp"
      id="kt_wizard"
      data-wizard-state="step-first"
      data-wizard-clickable="false"
    >
      {/* <!--begin::Wizard Body--> */}
      <div className="row justify-content-center my-10 px-1 my-lg-15 px-lg-10">
        <div className="col-xl-12">
          {/* <!--begin::Wizard Form--> */}
          <form className="form">
            <div
              className="pb-5"
              data-wizard-type="step-content"
              data-wizard-state="current"
            >
              <h2 className="font-weight-bold text-dark">{action}</h2>

              <div>
                {subAction ? <p>{subAction}</p> : ''}
                {isRetakingWizard ? (
                  <Exit cancelWizard={() => cancelWizard(history)} />
                ) : null}
              </div>
            </div>
            <div className="row">
              {choices.map(eachChoice => {
                return (
                  <div className="col-xl-12" key={eachChoice.id}>
                    {eachChoice.id === id ? (
                      <div
                        onClick={e => clickChoice(e, eachChoice.id)}
                        className="card card-custom wizard-custom-selector animated-card gutter-b"
                        style={{
                          background: '#0071CE',
                          color: '#ffffff',
                        }}
                      >
                        {/* <!--begin::Body--> */}
                        <div
                          className="card-body d-flex flex-row"
                          style={{ padding: '1.5rem 2.25rem' }}
                        >
                          <div className="font-weight-light font-size-h4">
                            {eachChoice.choice}
                            <span className="px-2" style={{ color: '#fff' }}>
                              {eachChoice.optionChoice &&
                                eachChoice.optionChoice}
                            </span>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        onClick={e => clickChoice(e, eachChoice.id)}
                        className="card animated-card card-custom wizard-custom-selector gutter-b"
                        style={{
                          background: 'rgba(0, 113, 206, 0.05)',
                          border: '0.5px solid rgba(0, 113, 206, 0.5)',
                          borderRadius: '5px',
                        }}
                      >
                        <div
                          className="card-body d-flex flex-row"
                          style={{ padding: '1.5rem 2.25rem' }}
                        >
                          <div className="font-weight-light font-size-h4">
                            {eachChoice.choice}
                            <span className="px-2" style={{ color: '#0071CE' }}>
                              {eachChoice.optionChoice &&
                                eachChoice.optionChoice}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            <div className="flex-wrap d-flex justify-content-between buttons-gap mt-10">
              <Link to={prev}>
                <CancelButton buttonText="Prev" buttonWidth={'width-140'} />
              </Link>

              {item ? (
                <Link to={next}>
                  <ConfirmButton
                    buttonText={'Next'}
                    buttonWidth={'width-140'}
                  />
                </Link>
              ) : null}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Selector;
