import React, { useState } from 'react';

import { HeaderSection } from './HeaderSection';
import { TransactionBlock } from './TransactionBlock';

export const Timeline = ({ transactionsTimeline }) => {
  const [displayTimeline, setDisplayTimeline] = useState(false);

  const toggleTimeline = () => setDisplayTimeline(!displayTimeline);

  return (
    <div style={{ maxWidth: '550px' }}>
      <HeaderSection
        onClick={toggleTimeline}
        displayTimeline={displayTimeline}
        transactionsNumber={transactionsTimeline?.length}
      />

      {displayTimeline && (
        <div className="mt-5 transactions-timeline-container">
          {transactionsTimeline.map(timeline => (
            <TransactionBlock key={timeline.id} timeline={timeline} />
          ))}
        </div>
      )}
    </div>
  );
};
