import axios from 'axios';
import dotenv from 'dotenv';

dotenv.config();

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const UPDATE_KYC_INFO = `${REACT_APP_BASE_URL}/v1/investor/investors/update-kyc`;
const UPDATE_USER_DETAILS = `${REACT_APP_BASE_URL}/v2/investor/investors/update-investor-details`;
const UPDATE_FINANCIAL_DETAILS = `${REACT_APP_BASE_URL}/v1/investor/investors/update-additional-info`;
const REQUEST_KYC_UPDATE = `${REACT_APP_BASE_URL}/v1/investor/investors/request-kyc-change`;

const UpdateKycInfo = async payload => {
  const res = await axios.put(UPDATE_KYC_INFO, payload);
  return res;
};

const updateUserDetails = async payload => {
  const res = await axios.put(UPDATE_USER_DETAILS, payload);
  return res;
};

const updateAdditionalInformation = async payload => {
  const res = await axios.put(UPDATE_FINANCIAL_DETAILS, payload);
  return res;
};

const requestKYCUpdate = async reason => {
  const res = await axios.post(REQUEST_KYC_UPDATE, { reason });
  return res;
};

export {
  UpdateKycInfo,
  updateUserDetails,
  updateAdditionalInformation,
  requestKYCUpdate,
};
