import React from 'react';

export const PayNow = ({ currency, amount }) => {
  return (
    <div className="text-16">
      <span>Pay Now: </span>
      <span className="text-primary-orange font-weight-600">
        {currency} {amount}
      </span>
    </div>
  );
};
