import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import {
  calculateNdovuFee,
  getNdovuFee,
} from '../../../../helpers/calculateNdovuFee';
import {
  mathSign,
  paymentMethod,
  paymentMethodCharge,
} from '../../../../helpers/constants';
import convertAmountToNumber from '../../../../helpers/convertAmountToNumber';
import { moneyFormat } from '../../../../helpers/moneyFormat';
import DisplayMoney from '../DisplayMoney';
import './index.scss';
import { BreakdownTitle } from './BreakdownTitle';

const InvestmentPaymentBreakdown = ({
  discount,
  amount,
  currency,
  planDetails,
  activePaymentMethod,
  minimumAmountError,
  isPocketTopup,
  offeredConversionRate,
}) => {
  const [discountedAmount, setDiscountedAmount] = useState(0);
  const { goalInvestmentDetails } = useSelector(state => state.goal);
  const { investor } = useSelector(state => state.profile);

  const ndovuPercentage = investor?.subscription.percentageFee;
  const pocketTopupDetails = useSelector(state => state.pocketTopupDetails);

  useEffect(() => {
    const discountedAmount = discount?.amount
      ? convertAmountToNumber(amount) - discount?.amount
      : convertAmountToNumber(amount);

    setDiscountedAmount(discountedAmount);
  }, [activePaymentMethod, amount, investor, discount.amount]);

  const calculateTrnxnFees = () => {
    if (discountedAmount <= 0) {
      return 0;
    }

    let paymentFee = 0;

    if (activePaymentMethod === paymentMethod.KENYAN_CARD) {
      paymentFee =
        (paymentMethodCharge.KENYAN_CREDIT_CARD_PERC * discountedAmount) / 100;
    } else if (activePaymentMethod === paymentMethod.INTERNATIONAL_CARD) {
      paymentFee =
        (paymentMethodCharge.INTERNATIONAL_CREDIT_CARD_PERC *
          discountedAmount) /
        100;
    }
    return paymentFee;
  };

  const calculateInvestedAmount = () => {
    const ndovuFee = calculateNdovuFee(
      goalInvestmentDetails?.goalId,
      amount,
      planDetails,
      discountedAmount,
      ndovuPercentage,
    );

    const investedAmount =
      convertAmountToNumber(amount) - Number(ndovuFee).toFixed(2);

    return investedAmount;
  };

  const trnxFees = calculateTrnxnFees();

  const calculatePayNow = () => {
    if (discountedAmount <= 0) {
      return 0;
    }

    const amountPlusTransactionFee =
      trnxFees > 0 ? discountedAmount + trnxFees : discountedAmount;

    return offeredConversionRate
      ? amountPlusTransactionFee * offeredConversionRate
      : amountPlusTransactionFee;
  };

  const ndovuFee = calculateNdovuFee(
    goalInvestmentDetails?.goalId,
    amount,
    planDetails,
    discountedAmount,
    ndovuPercentage,
  );

  return (
    <div className="border-light-blue-545 border-radius-8 p-5 mt-8">
      {planDetails && !isPocketTopup ? (
        <DisplayMoney
          label="Fund Name"
          value={planDetails?.coolName || planDetails?.name}
        />
      ) : isPocketTopup ? (
        <div className="font-weight-600 text-16">Pocket Top up</div>
      ) : null}

      <BreakdownTitle
        title={'How much am I paying now'}
        popoverMessage={
          'Transaction fees are charged by the payment provider, not ndovu.'
        }
      />

      {amount && (
        <DisplayMoney
          label="Deposit Amount"
          currency={currency}
          value={amount}
        />
      )}

      {discount?.amount && (
        <DisplayMoney
          label="Voucher Discount"
          currency={discount?.currency}
          value={discount?.amount}
          mathSign={mathSign.NEGATIVE}
        />
      )}

      {pocketTopupDetails?.currency &&
      pocketTopupDetails?.pocketCurrency &&
      pocketTopupDetails?.currency !== pocketTopupDetails?.pocketCurrency ? (
        <DisplayMoney
          label="Forex Conversion Rate"
          currency={currency}
          value={moneyFormat(offeredConversionRate)}
        />
      ) : null}

      <DisplayMoney
        label="Transaction Fee"
        currency={currency}
        value={moneyFormat(trnxFees.toFixed(2))}
        mathSign={trnxFees > 0 ? mathSign.POSITIVE : null}
      />

      <DisplayMoney
        label="Pay now"
        currency={currency}
        value={calculatePayNow()}
        minimumAmountError={minimumAmountError}
        keyCustomStyle={{ color: '#0071CE', fontWeight: 600 }}
        valueCustomStyle={{ color: '#0071CE' }}
      />

      {!isPocketTopup ? (
        <>
          <BreakdownTitle
            title={
              planDetails?.allowBongaPointPayment
                ? 'How much will I get'
                : 'How much will get invested'
            }
            popoverMessage={
              'We believe in being transparent. ndovu One-off Fee will be deducted from the payment amount upfront. Balance after ndovu One-off Fee deduction will be the final amount reflected in your portfolio.'
            }
          />

          <DisplayMoney
            label={`ndovu One-off Fee (${getNdovuFee(
              goalInvestmentDetails?.goalId,
              planDetails,
              ndovuPercentage,
            )}%)`}
            currency={currency}
            value={moneyFormat(ndovuFee)}
            mathSign={mathSign.NEGATIVE}
          />

          <DisplayMoney
            label={
              planDetails?.allowBongaPointPayment
                ? 'Total Value'
                : 'Total Invested'
            }
            currency={currency}
            value={calculateInvestedAmount()}
            keyCustomStyle={{ color: '#FF7F30', fontWeight: 600 }}
            valueCustomStyle={{ color: '#FF7F30' }}
          />
        </>
      ) : null}
    </div>
  );
};

export default InvestmentPaymentBreakdown;
