export const paymentMethod = {
  KENYAN_CARD: 'KENYAN_CARD',
  INTERNATIONAL_CARD: 'INTERNATIONAL_CARD',
  MPESA: 'MPESA',
  BANK_TRANSFER: 'BANK_TRANSFER',
  CARD: 'CARD',
  BONGA_POINT: 'BONGA_POINT',
  POCKET: 'POCKET',
  OTHERS: 'OTHERS',
};

export const sameCurrencyConversion = {
  KES_TO_KES: 'KES_TO_KES',
  USD_TO_USD: 'USD_TO_USD',
  RWF_TO_RWF: 'RWF_TO_RWF',

  EUR_TO_EUR: 'EUR_TO_EUR',
  GBP_TO_GBP: 'GBP_TO_GBP',
  TZS_TO_TZS: 'TZS_TO_TZS',
};

export const paymentMethodCharge = {
  KENYAN_CREDIT_CARD_PERC: 2.9,
  INTERNATIONAL_CREDIT_CARD_PERC: 3.8,
};

export const errorCodes = {
  BAD_REQUEST: 400,
  UNPROCESSABLE_ENTITY: 422,
};
export const planType = {
  CASH: 'cash',
  BOND: 'bond',
  EQUITY: 'equity',
};

export const errorMessages = {
  VERIFY_EMAIL: 'Please verify your email address',
};

export const environment = {
  DEVELOPMENT: 'development',
  PRODUCTION: 'production',
};

export const mathSign = {
  NEGATIVE: '-',
  POSITIVE: '+',
};

export const status = {
  PENDING: 'PENDING',
  TRANSFER_INCOMPLETE: 'TRANSFER_INCOMPLETE',
  RECONCILED: 'RECONCILED',
  COMPLETED: 'COMPLETED',
};

export const operationType = {
  DEPOSIT: 'DEPOSIT',
  WITHDRAW: 'WITHDRAW',
};

export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z\d])[A-Za-z\d\S]{8,40}$/;

export const passwordValidationMessage =
  'Must be in a range of 8-40 characters. At least one upper case letter, one lower case, and one number.';

export const letterNumberRegex = /^[a-zA-Z0-9',.&\- ]+$/;

export const specialCharError =
  "The only special characters allowed are: ('), (,), (.), (-) and (&)";

export const namesRegex = /^[a-zA-Z0-9',.-]+$/;

export const nameRegexError =
  "The only special characters allowed are: ('), (,), (.) and (-) and empty space is not allowed.";

export const allowWhiteSpace = /^(\w+ ?)*$/;

export const allowWhiteSpaceError =
  'The only special character allowed is a whitespace(space)';

export const subscriptionType = {
  STANDARD: 'STANDARD',
  BASIC: 'BASIC',
  ULTIMATE: 'ULTIMATE',
};

export const EmploymentStatus = [
  { value: 'Contractor', label: 'Contractor' },
  { value: 'Full Time', label: 'Full Time' },
  { value: 'Part Time', label: 'Part Time' },
  { value: 'Not employed', label: 'Not Employed' },
];

export const PaymentType = {
  SUBSCRIPTION_PAYMENT: 'SUBSCRIPTION_PAYMENT',
  INVESTMENT_PAYMENT: 'INVESTMENT_PAYMENT',
};

export const kycStatuses = {
  PENDING: 'PENDING',
  COMPLETED: 'COMPLETED',
  SUBMITTED: 'SUBMITTED',
  REJECTED: 'REJECTED',
  IN_EDIT: 'IN_EDIT',
};

export const Options = {
  MALE: 'MALE',
  FEMALE: 'FEMALE',
  NON_BINARY: 'NON_BINARY',
};

export const TransactionStatus = {
  ARCHIVED: 'ARCHIVED',
  COMPLETED: 'COMPLETED',
  PENDING: 'PENDING',
  TRANSFER_INCOMPLETE: 'TRANSFER_INCOMPLETE',
  RECONCILED: 'RECONCILED',
};

export const Currency = {
  KES: 'KES',
  DOLLAR: 'USD',
  RWF: 'RWF',
};

export const FundType = {
  SAVE: 'SAVE',
  INVEST: 'INVEST',
};

export const InvestmentAction = {
  DIRECT_INVESTMENT: 'DIRECT_INVESTMENT',
  DIRECT_INVESTMENT_TOPUP: 'PLAN_INVESTMENT_TOPUP',
  GOAL_INVESTMENT: 'GOAL_INVESTMENT',
  GOAL_TOPUP: 'GOAL_TOPUP',
  SUBSCRIPTION: 'SUBSCRIPTION',
  POCKET_TOPUP: 'POCKET_TOPUP',
};

export const SourceOfFunds = [
  { value: 'SALARY_OR_BUSINESS_PROFIT', label: 'Salary or business profit' },
  { value: 'BANK_CREDIT', label: 'Bank Credit' },
  { value: 'PERSONAL_SAVINGS', label: 'Personal Savings' },
  { value: 'PROPERTY_SALES', label: 'Property Sales' },
  { value: 'INHERITANCE', label: 'Inheritance' },
  { value: 'SHARE_SALES_OR_DIVIDEND', label: 'Share sales or dividends' },
];

export const FilterBy = [
  { value: 'MONTHLY', text: 'Month' },
  { value: 'WEEKLY', text: 'Week' },
  { value: 'YEARLY', text: 'Year' },
];

export const PredefinedColors = [
  '#0071CE',
  '#FF7F30',
  '#002891',
  '#6ECB5F',
  '#F54E4F',
  '#90CAF9',
  '#FF5000',
  '#CF1061',
  '#F48687',
  '#F05B7F',
];

export const AccountTypesList = [
  {
    label: 'Personal Account',
    description:
      'Start building your financial future with our Personal Savings Investment Account.',
    value: 'INDIVIDUAL',
  },
  {
    label: 'Business Account',
    description:
      "Maximise your returns while managing your company's funds efficiently and securely.",
    value: 'BUSINESS',
  },
  {
    label: 'Chama Account',
    description:
      'Join forces with friends, family, or colleagues and reach your savings goals together',
    value: 'CHAMA',
  },
];

export const OtherManagement = [
  { link: '/manage-account/security', text: 'Security' },
  { link: '/subscription', text: 'Account Plans' },
];

export const CreateAccountType = [
  {
    link: '/investor-information',
    text: 'Business Account',
    value: 'BUSINESS',
  },
  { link: '/investor-information', text: 'Chama Account', value: 'CHAMA' },
];

export const Settings = [
  { link: '/security/devices', text: 'Devices', id: 1 },
  { link: '/security/activities', text: 'Activities', id: 2 },
  { link: '/security/change-password', text: 'Change Password', id: 3 },
  { link: '/security/deactivate-account', text: 'Deactivate Account', id: 4 },
];

export const Accounts = [
  { path: '/devices', name: 'Thomas', type: 'Individual Account' },
  { path: '/devices', name: 'Herbert', type: 'Business Account' },
  { path: '/devices', name: 'Bruno', type: 'Chama Account' },
];

export const roles = [
  { label: 'Manager', value: 'MANAGER' },
  { label: 'Viewer', value: 'VIEWER' },
];

export const TeamManagerPermissions = [
  'Manage Users',
  'View Account',
  'Invest and Disinvest',
];

export const TeamAdminPermissions = [
  'Manage Users',
  'View Account',
  'Invest and Disinvest',
  'Pocket Withdrawals and Conversions',
  'Add and Delete Users',
  'Pocket topup',
];

export const TeamViewerPermissions = ['View Account Only'];

export const TeamMemberDefaultPermissions = [];

export const teamMemberRole = {
  ADMIN: 'ADMIN',
  MANAGER: 'MANAGER',
  VIEWER: 'VIEWER',
};

export const teamMemberPermissions = {
  INVEST_AND_DISINVEST: 'INVEST_AND_DISINVEST',
  VIEW_ACCOUNT: 'VIEW_ACCOUNT',
  USER_MANAGEMENT: 'USER_MANAGEMENT',
  KYC_MANAGEMENT: 'KYC_MANAGEMENT',
  SUBSCRIPTION: 'SUBSCRIPTION',
  VOUCHER_REDEMPTION: 'VOUCHER_REDEMPTION',
  WALLET_WITHDRAWAL_AND_CONVERSION: 'WALLET_WITHDRAWAL_AND_CONVERSION',
};

export const managerOperations = ['investment', 'disinvestment', 'delete_goal'];

export const teamMemberInvitesAction = {
  ACCEPT: 'ACCEPT',
  REJECT: 'REJECT',
};

export const invitesStatus = {
  PENDING: 'PENDING',
  ACCEPTED: 'ACCEPTED',
};

export const ALLOWED_CHARS_REGEX = /[^a-zA-Z0-9À-ž',+\-_ ]/g;

export const transactionsColumns = [
  'Date',
  'Fund Name',
  'Fund Manager',
  'Transaction Type',
  'Goal',
  'Amount',
  'Type',
  'Actioned By',
  'Email',
  'Status',
];

export const archivedTransactionsColumns = [
  'Date',
  'Fund Name',
  'Fund Manager',
  'Transaction Type',
  'Goal',
  'Amount',
  'Type',
  'Reason',
  'Refunded',
  'Actioned by',
  'Email',
  'Status',
];

export const PocketActions = [
  { label: 'Top Up', path: 'topup' },
  { label: 'Convert', path: 'conversion' },
  { label: 'Invest', path: 'investment' },
  { label: 'Withdraw', path: 'withdraw' },
];

export const pocketActionsEnum = {
  WITHDRAW: 'withdraw',
  CONVERSION: 'conversion',
  INVESTMENT: 'investment',
  DISINVESTMENT: 'disinvestment',
  TOPUP: 'topup',
  GOAL: 'goal',
};

export const numbersRegex = /^[0-9,]*$/;

export const investmentType = {
  TOP_UP: 'TOP_UP',
  INITIAL_INVESTMENT: 'INITIAL_INVESTMENT',
};

export const currencyFlags = {
  RWF: 'flag-icon-rw',
  KES: 'flag-icon-ke',
  TZS: 'flag-icon-tz',
  USD: 'flag-icon-us',
  EUR: 'flag-icon-eu',
  GBP: 'flag-icon-gb',
};

export const settlementDetails = [
  'Safaricom MPesa',
  'Airtel Money',
  'Bank of America',
  'DTB Bank',
];

export const pocketOperations = {
  CREDIT: 'CREDIT',
  DEBIT: 'DEBIT',
};

export const genderOptions = [
  { label: 'Male', value: 'MALE' },
  { label: 'Female', value: 'FEMALE' },
];

export const identificationType = {
  nationalId: 'National Id',
  passport: 'Passport',
};

export const SourceOfFundsObject = {
  SALARY_OR_BUSINESS_PROFIT: 'Salary or business profit',
  BANK_CREDIT: 'Bank Credit',
  PERSONAL_SAVINGS: 'Personal Savings',
  PROPERTY_SALES: 'Property Sales',
  INHERITANCE: 'Inheritance',
  SHARE_SALES_OR_DIVIDEND: 'Share sales or dividends',
};

export const idTypeOptions = {
  NATIONAL_ID: 'National ID',
  PASSPORT: 'Passport',
};

export const businessType = {
  SOLE_PROPRIETORSHIP: 'SOLE_PROPRIETORSHIP',
  INCORPORATED_BUSINESS: 'INCORPORATED_BUSINESS',
};

export const businessTypes = [
  { label: 'Sole Proprietorship', value: businessType.SOLE_PROPRIETORSHIP },
  { label: 'Incorporated Business', value: businessType.INCORPORATED_BUSINESS },
];

export const idTypes = [
  { label: 'National ID', value: 'NATIONAL_ID' },
  { label: 'Passport', value: 'PASSPORT' },
];

export const genderObject = {
  M: 'Male',
  F: 'Female',
};

export const pieChatTypes = {
  ASSETS: 'Asset Distribution',
  CURRENCIES: 'Currency Distribution',
  ASSETS_CLASSES: 'Asset Classes',
  PRODUCT_TYPE: 'Product Type',
};

export const serviceProviderOptions = [
  { label: 'Safaricom M-Pesa', value: 'MPESA' },
];

export const countryCode = {
  KE: 'KE',
};

export const countryName = {
  KENYA: 'Kenya',
};

export const goalNdovuFee = 0;

export const donutChartcolors = {
  'Fixed Return funds': '#0071CE',
  'ETFs (Variable funds)': '#FF7F30',
};

export const subscriptionCardColor = {
  BASIC: { background: '#FAFCFE', color: '#3F4254', featureColor: '#FF7F30' },
  STANDARD: {
    background: '#0071CE',
    color: '#DEECF7',
    featureColor: '#ffffff',
  },
  ULTIMATE: {
    background: '#FAFCFE',
    color: '#3F4254',
    featureColor: '#ff7f30',
  },
};

export const ProfitType = {
  VARIABLE: 'VARIABLE',
  FIXED: 'FIXED',
  FIX_SIMPLE: 'FIX_SIMPLE',
  FIX_COMPOUND: 'FIX_COMPOUND',
};

export const acquisitionChannel = [
  { label: 'Facebook', value: 'FACEBOOK' },
  { label: 'LinkedIn', value: 'LINKEDIN' },
  { label: 'Google Search', value: 'GOOGLE_SEARCH' },
  { label: 'Email', value: 'EMAIL' },
  { label: 'Billboards', value: 'BILLBOARDS' },
  { label: 'Word of Mouth', value: 'WORD_OF_MOUTH' },
  { label: 'Other', value: 'OTHER' },
];

export const settlementDetailsStatuses = {
  ON_HOLD: 'ON_HOLD',
  REJECTED: 'REJECTED',
  SUBMITTED: 'SUBMITTED',
};

export const serviceProviders = {
  MPESA: 'MPESA',
};

export const settlementDetailsType = {
  MOBILE_MONEY: 'MOBILE_MONEY',
  BANK: 'BANK',
};

export const accountTypes = {
  CHAMA: 'CHAMA',
  BUSINESS: 'BUSINESS',
  INDIVIDUAL: 'INDIVIDUAL',
};

export const otherBank = {
  bankId: 'BANK30',
  centralBankCode: '030',
  id: 30,
  name: 'Others',
  sortCode: '1140402000',
};

export const kycFlowActions = {
  SUBMITTING_KYC_INFORMATION: 'SUBMITTING_KYC_INFORMATION',
  UPDATING_KYC_INFORMATION: 'UPDATING_KYC_INFORMATION',
};

export const paymentOptions = {
  KENYAN_CARD: 'KENYAN_CARD',
  INTERNATIONAL_CARD: 'INTERNATIONAL_CARD',
  MPESA: 'MPESA',
  CARD: 'CARD',
  BANK_TRANSFER: 'BANK_TRANSFER',
  BONGA_POINT: 'BONGA_POINT',
  AIRTEL: 'AIRTEL',
  USER_POCKET: 'USER_POCKET',
};

export const pocketTransactionType = {
  GOAL: 'GOAL',
  DIRECT: 'DIRECT',
};

export const platformType = {
  WEB_APP: 'WEB_APP',
};

export const customerIOEventType = {
  START_KYC: 'START_KYC', // when user starts KYC process
  INVEST_ON_HOME_PAGE: 'INVEST_ON_HOME_PAGE', // when user clicks invest from the menu
  INVEST_ON_PRODUCT_PAGE: 'INVEST_ON_PRODUCT_PAGE', // when user clicks invest inside selected fund
  ADD_NEW_GOAL: 'ADD_NEW_GOAL', // when user clicks add new goal
  PAY_NOW: 'PAY_NOW', // when user clicks pay now while investing
  PAYMENT_CONFIRMATION: 'PAYMENT_CONFIRMATION', // when user clicks confirm on payment page
};

export const menuNames = {
  INVEST: 'new-investment',
};

export const recurringPaymentStatus = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  EXPIRED: 'EXPIRED',
  CANCELLED: 'CANCELLED',
};

export const recurringPaymentModalOptions = [
  {
    id: 1,
    key: 'BANK_TRANSFER',
    value: 'Bank Recurring Payment',
  },
  {
    id: 2,
    key: 'MPESA',
    value: 'M-PESA Reminder',
  },
];

export const emailVerificationErrors = {
  USER_NOT_FOUND: 'USER_NOT_FOUND',
  ACCOUNT_IS_ALREADY_VERIFIED: 'ACCOUNT_IS_ALREADY_VERIFIED',
  INVALID_OTP_CODE: 'INVALID_OTP_CODE',
  VERIFICATION_CODE_EXPIRED: 'VERIFICATION_CODE_EXPIRED',
};

export const googleAuth = {
  GOOGLE_SIGN_IN: 'GOOGLE_SIGN_IN',
  GOOGLE_SIGN_UP: 'GOOGLE_SIGN_UP',
};

export const googleAuthGenericError =
  'Unable to sign in with google. Please ensure you allow the necessary permissions and your third-party cookies are enabled to proceed with Google';

export const mobileMoneyCurrencies = [Currency.KES, Currency.RWF];
