import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { accountTypes } from '../../../helpers/constants';
import { handleKycRouting } from '../../../helpers/handleKycRouting';
import Back from '../../Common/BackArrow';
import { SettingsPageLink } from '../components/SettingsPageLink';
import {
  BusinessManagement,
  ChamaManagement,
  PersonalManagement,
} from '../helpers/getManageAccountLinks';
import { trackEventMixpanel } from '../../../eventTracking/mixpanel';
import { FullWidthWrapper } from '../../Common/FullWidthWrapper';

const ManageAccount = () => {
  const history = useHistory();

  const { investor } = useSelector(state => state.profile);
  const { kycLevel, kycStatus, pendingKyc, investorType } = investor;

  const kycChecks = { kycLevel: kycLevel?.level, kycStatus, pendingKyc };

  const previousPage = localStorage.getItem('kycEntryPointUrl');

  const handleManageAccountLinkClick = linkItem => {
    localStorage.setItem('previousPage', history.location.pathname);
    linkItem?.eventName && trackEventMixpanel(linkItem?.eventName);

    linkItem?.kyc
      ? history.push(handleKycRouting(kycChecks, linkItem.link, investorType))
      : history.push(linkItem.link);
  };

  return (
    <>
      <Back
        title={'Manage Account'}
        text={'Back'}
        previousPageLink={previousPage}
      />

      <FullWidthWrapper>
        <div className="business-overview d-flex flex-column pl-md-0">
          {/* TODO: ADD PERMISSIONS CHECK */}

          {(investor?.investorType === accountTypes.INDIVIDUAL
            ? PersonalManagement
            : investor?.investorType === accountTypes.BUSINESS
            ? BusinessManagement
            : ChamaManagement
          ).map(linkItem => (
            <SettingsPageLink
              pageName={linkItem.text}
              handlePageRedirection={() =>
                handleManageAccountLinkClick(linkItem)
              }
              key={linkItem?.id}
            />
          ))}
        </div>
      </FullWidthWrapper>
    </>
  );
};

export default ManageAccount;
