import React from 'react';

export const PreferenceContainer = ({ children }) => {
  return (
    <div className="card card-custom wizard-custom-selector gutter-b bg-light-blue-200 border-light-blue border-radius-3">
      <div
        className="card-body d-flex flex-row justify-content-between align-items-center "
        style={{ padding: '10px 17px' }}
      >
        {children}
      </div>
    </div>
  );
};
