import React from 'react';
import SVG from 'react-inlinesvg';

export const HeaderSection = ({
  onClick,
  displayTimeline,
  transactionsNumber,
}) => (
  <div
    className={`d-flex justify-content-between align-items-center mt-5 ${
      displayTimeline ? '' : 'border-light-blue-540 border-radius-4 py-3 px-4'
    }`}
  >
    <div className="text-16 font-weight-600">{`Pending Transactions (${transactionsNumber})`}</div>
    <div className="cursor-pointer" onClick={onClick}>
      <SVG
        src={`/media/svg/ndovu/${
          displayTimeline ? 'arrow-down-grey' : 'arrow-right-grey'
        }.svg`}
      />
    </div>
  </div>
);
