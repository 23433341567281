import React from 'react';

export const UpgradeButton = ({ handleSubscribe, disabled }) => {
  return (
    <button
      type="submit"
      className={`${
        disabled ? '' : 'cursor-pointer'
      } py-2 btn font-weight-bold px-6`}
      onClick={handleSubscribe}
      style={{
        color: '#fff',
        background: disabled ? '#d6d2d2' : 'rgb(255, 127, 48)',
        fontFamily: 'Poppins',
      }}
      disabled={disabled}
    >
      <span>Upgrade</span>
    </button>
  );
};
