/* eslint-disable jsx-a11y/anchor-is-valid */

import * as Yup from 'yup';

import React, { useState } from 'react';

import Alert from '../../Common/Alert';
import { editPassword } from '../_redux/profileCrud';
import { useFormik } from 'formik';
import logout from '../../Auth/pages/Logout';
import {
  passwordRegex,
  passwordValidationMessage,
} from '../../../helpers/constants';
import Back from '../../Common/BackArrow';
import { PlanDetailsCard } from '../../Plan/components/PlanDetailsCard';
import { CancelButton, CustomButton } from '../../Common/ReusableButtons';
import { PasswordInput } from '../../Common/PasswordInput';
import useMediaQuery from '@mui/material/useMediaQuery';
import { PasswordValidationErrorMessage } from '../../Common/PasswordValidationErrorMessage';

const initialValues = {
  oldPassword: '',
  newPassword: '',
  confirmPassword: '',
};

const ChangePassword = () => {
  const [alertOn, setAlertOn] = useState(false);
  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });

  const [viewPassword, setViewPassword] = useState(false);
  const [viewConfirmPassword, setViewConfirmPassword] = useState(false);
  const [viewCurrentPassword, setViewCurrentPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const smallDevice = useMediaQuery('(max-width : 590px)');

  const ChangePasswordSchema = Yup.object().shape({
    oldPassword: Yup.string().required('Required field'),
    newPassword: Yup.string()
      .required('Required field')
      .matches(passwordRegex, passwordValidationMessage),
    confirmPassword: Yup.string()
      .required('Required field')
      .when('newPassword', {
        is: val => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref('newPassword')],
          "Password and Confirm Password didn't match",
        ),
      }),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: ChangePasswordSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        delete values.confirmPassword;
        const response = await editPassword(values);
        setSubmitting(false);
        if (response.status === 200) {
          formik.resetForm();
          setAlertOn(true);
          setAlert({
            alertMessage: 'password edited successfully.',
            alertMessageType: 'success',
          });
          setTimeout(() => {
            setAlertOn(!setAlertOn);
          }, 3000);
          setLoading(false);
        }
      } catch (error) {
        setSubmitting(false);
        setAlertOn(true);
        setAlert({
          alertMessage:
            error.response.data && error.response.data.message
              ? error.response.data.message
              : 'profile editing unsuccessful.Try again.',
          alertMessageType: 'error',
        });
        setTimeout(() => {
          setAlertOn(!setAlertOn);
        }, 3000);
        setStatus(error.response.data.message);
        formik.resetForm();
        setLoading(false);
      }
    },
  });

  return (
    <>
      <Back text="Back" />
      <div id="add-margin-top" className="flex-row-fluid">
        <form className="form" onSubmit={formik.handleSubmit}>
          {/* <!--begin::Card--> */}
          <PlanDetailsCard
            title={
              <div className="d-flex justify-content-between align-items-center px-7">
                <div className="tex-16 tex-blue font-weight-500">
                  Change Password
                </div>

                {!smallDevice ? (
                  <div className="d-flex buttons-gap">
                    <CancelButton
                      buttonText={'Cancel'}
                      handleOnClick={formik.handleReset}
                      buttonWidth={'width-170'}
                    />

                    <CustomButton
                      buttonText={'Save Changes'}
                      buttonType={'submit'}
                      disable={
                        Object.keys(formik.errors).length > 0 ||
                        Object.keys(formik.touched).length === 0
                      }
                      loading={loading}
                      buttonWidth={'width-170'}
                      classes={'bg-light-blue-530 text-blue'}
                    />
                  </div>
                ) : null}
              </div>
            }
          >
            {/* <!--begin::Header--> */}

            <div className="p-7">
              {alertOn && <Alert alert={alert} />}
              <div className="mt-3 form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                  Current Password
                </label>
                <div className="col-lg-9 col-xl-6">
                  <PasswordInput
                    viewPassword={viewCurrentPassword}
                    formik={formik}
                    setViewPassword={setViewCurrentPassword}
                    name="oldPassword"
                    placeholder="Current Password"
                    passwordContainerSpacing={'mb-5'}
                  />
                  <div
                    className="text-13 text-blue cursor-pointer"
                    onClick={() => {
                      logout();
                    }}
                  >
                    Forgot password ?
                  </div>
                </div>
              </div>

              {/* begin: Password */}
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                  New Password
                </label>
                <div className="col-lg-9 col-xl-6">
                  <PasswordInput
                    viewPassword={viewPassword}
                    formik={formik}
                    setViewPassword={setViewPassword}
                    name={'newPassword'}
                    placeholder="New Password"
                    passwordContainerSpacing="mb-1"
                  >
                    <div className="mt-5">
                      {formik.touched.newPassword ? (
                        <PasswordValidationErrorMessage
                          thereIsAnError={formik.errors.newPassword}
                        />
                      ) : null}
                    </div>
                  </PasswordInput>
                </div>
              </div>

              {/* Password confirmation*/}
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                  Confirm Password
                </label>
                <div className="col-lg-9 col-xl-6">
                  <PasswordInput
                    viewPassword={viewConfirmPassword}
                    formik={formik}
                    setViewPassword={setViewConfirmPassword}
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    passwordContainerSpacing={'mb-1'}
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-xl-3 col-lg-3 "></div>
                <span className="mt-1 col-lg-9 col-xl-6 font-size-sm">
                  Password Must contain minimum of 8 characters. At least one
                  letter and one number.
                </span>
              </div>

              {smallDevice ? (
                <div className="d-flex flex-column buttons-gap">
                  <CustomButton
                    buttonText={'Save Changes'}
                    buttonType={'submit'}
                    disable={
                      Object.keys(formik.errors).length > 0 ||
                      Object.keys(formik.touched).length === 0
                    }
                    loading={loading}
                    buttonWidth={'w-100'}
                    classes={'bg-light-blue-530 text-blue'}
                  />

                  <CancelButton
                    buttonText={'Cancel'}
                    handleOnClick={formik.handleReset}
                    buttonWidth={'w-100'}
                    classes={'mt-2'}
                  />
                </div>
              ) : null}
            </div>
            {/* </form> */}
            {/* <!--end::Form--> */}
          </PlanDetailsCard>
        </form>
      </div>
    </>
  );
};

export default ChangePassword;
