import React from 'react';

import { TransactionHeader } from './TransactionHeader';
import { TimelineSteps } from './TimelineSteps';
import { HorizontalLine } from '../../../Common/HorizontalLine';

export const TransactionBlock = ({ timeline }) => {
  const { id, amount, currency, operationType } = timeline;

  return (
    <div key={id}>
      <TransactionHeader
        amount={amount}
        currency={currency}
        operationType={operationType}
      />

      <div className="timeline-container">
        <TimelineSteps timeline={timeline} />
      </div>

      <HorizontalLine classes="timeline-horizontal-line navi-spacer-x-0 p-0 border-b-gray my-5" />
    </div>
  );
};
