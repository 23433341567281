import { useFormik } from 'formik';
import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { actions as investorProfileActions } from '../../Profile/_redux/profileRedux';

import { marketingSiteLinks } from '../../../utils/marketingSiteLinks';
import { ButtonsContainer } from '../../Common/ButtonsContainer';
import CheckBox from '../../Common/CheckBox';
import CustomInput from '../../Common/CustomInput';
import ExternalPageTitle from '../../Common/ExternalPageTitle/ExternalPageTitle';
import { PasswordInput } from '../../Common/PasswordInput';
import { CancelButton, ConfirmButton } from '../../Common/ReusableButtons';
import { DoYouHaveAccount } from '../Components/DoYouHaveAccount';
import { signupInitialValues } from '../InitialValues';
import { signupSchema, signupSchemaForGoogleSignup } from '../Schemas';
import { register, signupWithGoogle } from '../_redux/authCrud';
import * as auth from '../_redux/authRedux';
import '../styles/registration.scss';
import { platformType } from '../../../helpers/constants';
import { PasswordValidationErrorMessage } from '../../Common/PasswordValidationErrorMessage';

const Registration = props => {
  const { intl, userDetails, originPartnerId, campaignTrackingData } = props;

  const [loading, setLoading] = useState(false);
  const [viewPassword, setViewPassword] = useState(false);
  const [viewConfirmPassword, setViewConfirmPassword] = useState(false);

  const profile = useSelector(state => state.profile);

  const userHasSignedInWithGoogle = profile?.user?.hasSignedInWithGoogle;

  const dispatch = useDispatch();

  const history = useHistory();

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: signupInitialValues(
      userDetails?.phoneNumber ? { ...userDetails } : { ...profile.user },
    ),
    validationSchema: userHasSignedInWithGoogle
      ? signupSchemaForGoogleSignup(intl)
      : signupSchema(intl),
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      try {
        enableLoading();
        const wizardData = JSON?.parse(localStorage?.getItem('wizard'));

        let payload = {
          email: values.email.toLowerCase(),
          firstName: values.firstName,
          lastName: values.lastName,
          registrationPlatform: platformType.WEB_APP,
        };

        if (!userHasSignedInWithGoogle) {
          payload.password = values.password;
        }

        if (originPartnerId) {
          payload.originPartnerId = originPartnerId;
        }

        if (wizardData) {
          payload = { ...payload, ...wizardData };
        }

        const response = userHasSignedInWithGoogle
          ? await signupWithGoogle(payload)
          : await register(payload, campaignTrackingData);

        disableLoading();
        setSubmitting(false);

        let investorInformation;
        let userData;

        if (userHasSignedInWithGoogle) {
          investorInformation = response?.data?.user?.investor;
          delete response?.data?.user?.investor;

          userData = {
            ...profile?.user,
            ...response?.data?.user,
          };
        } else {
          investorInformation = response?.data?.data?.investor;
          delete response?.data?.data?.investor;

          userData = {
            ...profile?.user,
            ...response?.data?.data,
          };
        }

        dispatch(
          investorProfileActions.profileAction({
            ...profile,
            user: userData,
            investor: { ...profile?.investor, ...investorInformation },
            accessToken: response?.data?.accessToken,
          }),
        );

        localStorage.removeItem('idToken');
        localStorage.removeItem('wizard');

        disableLoading();
        setSubmitting(false);

        if (userHasSignedInWithGoogle) {
          localStorage.setItem('ndovuToken', response?.data?.accessToken);
          history.push('/onboarding/contact-information');
        } else {
          history.push('/auth/success');
        }
      } catch (error) {
        setSubmitting(false);
        setStatus(error?.response?.data?.message);
        disableLoading();
      }
    },
  });

  return (
    <div className="login-form login-signin" style={{ display: 'block' }}>
      {!userHasSignedInWithGoogle ? (
        <>
          <ExternalPageTitle
            title="Create account"
            subTitle="Enter your details to create an account"
          />
        </>
      ) : null}

      <div className="text-14 text-blue font-weight-500">
        Ensure your full name exactly matches your national ID
      </div>

      <form
        id="kt_login_signin_form"
        className="form fv-plugins-bootstrap fv-plugins-framework mt-n3"
        onSubmit={formik.handleSubmit}
      >
        {/* begin: Alert */}
        {formik.status ? (
          <div className="my-5">
            <div className="text-left font-weight-bold text-red-500">
              {formik.status}
            </div>
          </div>
        ) : null}
        {/* end: Alert */}

        {/* begin: First name */}
        <CustomInput
          type="text"
          placeholder="First name"
          name="firstName"
          formik={formik}
          inputWidth={'default'}
        />
        {/* end: First name */}

        {/* begin: Lastname */}
        <CustomInput
          type="text"
          placeholder="Last name"
          name="lastName"
          formik={formik}
          inputWidth={'default'}
        />
        {/* end: Last name */}

        {/* begin: Email */}
        <CustomInput
          type="email"
          placeholder="Email"
          name="email"
          formik={formik}
          inputWidth={'default'}
          disabled={userHasSignedInWithGoogle}
        />
        {/* end: Email */}

        {!userHasSignedInWithGoogle ? (
          <>
            {/* begin: Password */}
            <PasswordInput
              viewPassword={viewPassword}
              formik={formik}
              setViewPassword={setViewPassword}
              name={'password'}
              placeholder="Password"
            >
              <div className="mt-5">
                {formik.touched.password ? (
                  <PasswordValidationErrorMessage
                    thereIsAnError={formik.errors.password}
                  />
                ) : null}
              </div>
            </PasswordInput>
            {/* end: Password */}

            {/* begin: Confirm Password */}
            <PasswordInput
              viewPassword={viewConfirmPassword}
              formik={formik}
              setViewPassword={setViewConfirmPassword}
              name="changePassword"
              placeholder="Confirm Password"
              passwordContainerSpacing={'mt-10 mb-7'}
            />
            {/* end: Confirm Password */}
          </>
        ) : null}

        {/* begin: Terms and Conditions */}
        <CheckBox
          name="acceptTerms"
          formik={formik}
          customLabel={
            <div className="text-11">
              <span className="text-dark-gray-450">
                I have read and agree with
              </span>{' '}
              <a
                href={marketingSiteLinks.termsAndConditions}
                className="text-blue"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Service
              </a>{' '}
              <span className="text-dark-gray-450">&</span>{' '}
              <a
                className="text-blue"
                href={marketingSiteLinks.privacy}
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy policy
              </a>
            </div>
          }
        />

        {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">{formik.errors.acceptTerms}</div>
          </div>
        ) : null}
        {/* end: Terms and Conditions */}

        <ButtonsContainer justifyContent="justify-content-between">
          {' '}
          <div className="auth-buttons-size">
            <Link to="/auth/login" className="w-100">
              <CancelButton buttonText="Cancel" buttonWidth={'w-100'} />
            </Link>
          </div>
          <ConfirmButton
            buttonType={'submit'}
            buttonText="Sign up"
            loading={loading}
            disable={formik.isSubmitting || !formik.values.acceptTerms}
            buttonWidth={'auth-buttons-size'}
            changeText={false}
          />
        </ButtonsContainer>

        <DoYouHaveAccount
          question="Don't have an account?"
          action={'Sign in'}
          link={'/auth/login'}
          margin="mt-4"
        />
      </form>
    </div>
  );
};

export default injectIntl(connect(null, auth.actions)(Registration));
