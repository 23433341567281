import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions as subscriptionActions } from './_redux/subscriptionReducers';

import { subscriptionCopy } from '../../utils/subscriptionCopy';
import Alert from '../Common/Alert';
import Back from '../Common/BackArrow';
import Loader from '../Common/Loader';
import ConfirmDowngrade from './ConfirmDowngrade';
import { fetchSubscriptions } from './_redux/subscriptionActions';
import Subscription from './subscription';
import { FullWidthWrapper } from '../Common/FullWidthWrapper';
import { AnimatedPageContainer } from '../Common/AnimatedPageContainer';

const Subscriptions = () => {
  const [alertOn, setAlertOn] = useState(false);
  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });

  const [subscriptions, setSubscriptions] = useState();
  const [confirm, setConfirm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [
    planPricePerUserPreferredCurrency,
    setPlanPricePerUserPreferredCurrency,
  ] = useState(null);

  const { investor } = useSelector(state => state.profile);
  const userPreferredCurrency = investor?.currency?.currency;

  const accountPlansList = useSelector(state => state.accountPlansList);

  const dispatch = useDispatch();

  useEffect(() => {
    const getSubscriptions = async () => {
      try {
        const subscriptionsResults = await fetchSubscriptions();
        const { data } = subscriptionsResults?.data;
        setLoading(false);
        setSubscriptions(data);
        currencyIsSupported(data);
        dispatch(subscriptionActions.updateAccountPlansList(data));
      } catch (error) {
        setLoading(false);
        setAlertOn(true);
        setAlert({
          alertMessage: error?.response?.data?.message,
          alertMessageType: 'error',
        });
      }
    };

    getSubscriptions();
  }, []);

  const currencyIsSupported = accountPlans => {
    const supportedCurrency = accountPlans[2]?.prices?.find(
      planPrice => planPrice?.currency === userPreferredCurrency,
    );

    setPlanPricePerUserPreferredCurrency(supportedCurrency);

    // This might be used in future for better user experience

    // if (supportedCurrency) {
    //   setPlanPricePerUserPreferredCurrency(supportedCurrency);
    // } else {
    //   setAlertOn(true);
    //   setAlert({
    //     alertMessage:
    //       'Your default currency is not supported to upgrade subscription. Please, contact our customer service.',
    //     alertMessageType: 'error',
    //   });

    //   setPlanPricePerUserPreferredCurrency(null);
    // }
  };

  const previousPage = localStorage.getItem('previousPage');

  return (
    <AnimatedPageContainer>
      <Back title="Account Plans" previousPageLink={previousPage} />
      {alertOn ? <Alert alert={alert} /> : null}
      {confirm ? (
        <ConfirmDowngrade
          subscription={subscriptions.find(item => item.id === 1)}
          setConfirm={setConfirm}
          setAlertOn={setAlertOn}
          setAlert={setAlert}
        />
      ) : loading ? (
        <Loader />
      ) : (
        <FullWidthWrapper>
          {accountPlansList && accountPlansList.length && (
            <div className="mx-5 my-10 text-center text-13 font-weight-500">
              {subscriptionCopy?.description}
            </div>
          )}
          <div className="row text-center px-md-1">
            {accountPlansList &&
              accountPlansList.length &&
              accountPlansList.map(accountPlan => (
                <Subscription
                  key={accountPlan.id}
                  subscription={accountPlan}
                  planPrice={planPricePerUserPreferredCurrency}
                  setAlert={setAlert}
                  setAlertOn={setAlertOn}
                  setConfirm={setConfirm}
                  currentPlan={accountPlan?.id === investor?.subscription?.id}
                  disableUpgradeButton={
                    planPricePerUserPreferredCurrency === null
                  }
                />
              ))}
          </div>
        </FullWidthWrapper>
      )}
    </AnimatedPageContainer>
  );
};

export default Subscriptions;
