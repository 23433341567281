import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';

import { actions as planReducerActions } from '../Plan/_redux/planReducers';

import PayWithMobileMoney from './components/PayWithMobileMoney';
import { getPayingNowAmount } from '../../helpers/getPayingNowAmount';
import convertAmountToNumber from '../../helpers/convertAmountToNumber';
import { directInvestment, fundTopUp } from '../Plan/_redux/plansActions';
import { fetchPromotionDetails } from './_redux/investmentAction';
import { getCardPaymentModal } from '../..//helpers/cardPaymentModal';
import PayWithBankTransfer from './components/PayWithBankTransfer';
import InvestmentPaymentDetailsPage from './components/InvestmentPaymentDetailsPage';
import ApplyVoucher from './components/ApplyVoucher';
import { InvestmentAction } from '../../helpers/constants';
import PayWithBongaPoints from './components/PayWithBongaPoints';
import Back from '../Common/BackArrow';
import { AnimatedPageContainer } from '../Common/AnimatedPageContainer';
import { getCountryDetailsPerCurrency } from '../../helpers/getCountryDetails';

const handleFundTopUp = async (
  planSubscribtionId,
  textReference,
  channel,
  transactionId,
) => {
  const response = await fundTopUp(
    parseInt(planSubscribtionId),
    textReference,
    channel,
    parseInt(transactionId),
  );
  return response;
};

const handleDirectInvestment = async (
  id,
  textReference,
  channel,
  transactionId,
) => {
  const response = await directInvestment(
    parseInt(id),
    textReference,
    channel,
    parseInt(transactionId),
  );
  return response;
};

const { DIRECT_INVESTMENT, DIRECT_INVESTMENT_TOPUP } = InvestmentAction;

const actions = [
  { name: DIRECT_INVESTMENT, invest: handleDirectInvestment },
  { name: DIRECT_INVESTMENT_TOPUP, invest: handleFundTopUp },
];

const PlanInvestmentPayment = () => {
  let history = useHistory();
  const { subscription } = useSelector(state => state.profile);
  const [planInvestmentDtls, setPlanInvestmentDtls] = useState();

  const [alertOn, setAlertOn] = useState(false);
  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });
  const [loading, setLoading] = useState(false);
  const [discount, setDiscount] = useState({});
  const [totalAmount, setTotalAmount] = useState();
  const [useVoucher, setUseVoucher] = useState(false);
  const [payWithMpesa, setPayWithMpesa] = useState(false);
  const [payWithBankTransfer, setPayWithBankTransfer] = useState(false);
  const [activePaymentMethod, setActivePaymentMethod] = useState(null);
  const [payWithBongaPoints, setpayWithBongaPoints] = useState(false);

  const reduxPlanInvestmentDtls = useSelector(state => state?.plan);
  const { goalInvestmentDetails } = useSelector(state => state?.goal);

  const investmentAction = useSelector(
    state => state?.investment?.investmentAction,
  );

  const pocketTopupDetails = useSelector(state => state.pocketTopupDetails);

  const dispatch = useDispatch();

  useEffect(() => {
    const localStorageData = JSON.parse(localStorage.getItem('discountInfo'));
    setDiscount({
      amount: localStorageData?.discountAmount,
      currency: localStorageData?.currency,
    });
    if (
      reduxPlanInvestmentDtls?.planInvestmentDetails?.amount &&
      localStorageData?.discountAmount
    ) {
      setTotalAmount(
        getPayingNowAmount(
          convertAmountToNumber(
            reduxPlanInvestmentDtls?.planInvestmentDetails?.amount,
          ),
          localStorageData?.discountAmount,
        ),
      );
    }
    // if (
    //   reduxPlanInvestmentDtls?.planInvestmentDetails?.planId ||
    //   reduxPlanInvestmentDtls?.planInvestmentDetails?.fundId
    // ) {
    //   setPlanInvestmentDtls(reduxPlanInvestmentDtls?.planInvestmentDetails);
    // }

    setPlanInvestmentDtls(reduxPlanInvestmentDtls?.planInvestmentDetails);
  }, [reduxPlanInvestmentDtls]);

  const initialValues = {
    promoCode: planInvestmentDtls?.planInvestmentDetails?.promoCode,
  };

  const PromoCodeSchema = Yup.object().shape({
    promoCode: Yup.string()
      .min(8, 'Promo code must be 8 character long')
      .max(8, 'Promo code must be 8 character long'),
  });

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }
    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }
    return '';
  };

  // Change Voucher code
  const handleChange = event => {
    const { name, value } = event.target;
    setPlanInvestmentDtls({
      ...planInvestmentDtls,
      [name]: value,
    });
  };

  const alertRef = useRef(null);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: PromoCodeSchema,
  });

  const payWithCreditCard = async channel => {
    if (!planInvestmentDtls?.planId && !planInvestmentDtls?.fundId) {
      history.push('/new-investment/plans');
      return;
    }
    setLoading(true);
    const { amount, currency } = planInvestmentDtls;
    try {
      const response = await getCardPaymentModal(
        convertAmountToNumber(amount),
        currency,
        planInvestmentDtls?.promoCode || localStorage.getItem('promoCode'),
        channel,
      );

      if (!response?.data?.data) {
        const { data } = response;
        const id = planInvestmentDtls.planId
          ? planInvestmentDtls.planId
          : planInvestmentDtls.fundId;
        actions.forEach(async item => {
          if (item.name === investmentAction) {
            const result = await item.invest(
              parseInt(id),
              data?.tx_ref,
              channel,
            );

            if (result.status === 201) {
              setLoading(false);
              localStorage.removeItem('goalDataForPayment');
              localStorage.removeItem('discountInfo');
              localStorage.removeItem('promoCode');
              localStorage.removeItem('planCurrency');
              localStorage.removeItem('promoPlanOnOffer');
              history.push('/new-investment/congratulations');
              return;
            }
          }
        });
        return;
      }
      localStorage.removeItem('discountInfo');
      localStorage.removeItem('promoCode');
      const { data: { data: { link } = {} } = {} } = response;
      window.location.replace(link);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setAlertOn(true);
      setAlert({
        alertMessage: error.response?.data?.message,
        alertMessageType: 'error',
      });
      alertRef.current.scrollIntoView();
    }
  };

  const applyPromoCode = async () => {
    setAlertOn(false);
    if (!planInvestmentDtls.promoCode) {
      setAlertOn(true);
      setAlert({
        alertMessage: 'Promo code can not be empty',
        alertMessageType: 'error',
      });
      return;
    }

    if (formik.errors.promoCode) {
      setAlertOn(true);
      setAlert({
        alertMessage: formik.errors.promoCode,
        alertMessageType: 'error',
      });
      return;
    }

    try {
      setLoading(true);
      localStorage.setItem('promoCode', planInvestmentDtls.promoCode);
      const response = await fetchPromotionDetails(
        planInvestmentDtls.promoCode,
        planInvestmentDtls.currency,
      );
      const {
        data: { data: { amount: discountAmount, currency } = {} } = {},
      } = response;
      setDiscount({ amount: discountAmount, currency });

      const amountNumber = convertAmountToNumber(planInvestmentDtls?.amount);
      const amountTOPay = getPayingNowAmount(amountNumber, discountAmount);

      setTotalAmount(amountTOPay);
      localStorage.setItem(
        'discountInfo',
        JSON.stringify({
          discountAmount,
          currency,
          totalAmount: amountTOPay,
        }),
      );

      dispatch(
        planReducerActions.updatePlanInvestmentDetails(planInvestmentDtls),
      );

      setUseVoucher(false);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setAlertOn(true);
      setAlert({
        alertMessage: err.response.data.message,
        alertMessageType: 'error',
      });
    }
  };

  const currencyPerOperations = {
    [InvestmentAction.DIRECT_INVESTMENT]: planInvestmentDtls?.currency,
    [InvestmentAction.DIRECT_INVESTMENT_TOPUP]: planInvestmentDtls?.currency,
    [InvestmentAction.POCKET_TOPUP]: pocketTopupDetails?.currency,
    [InvestmentAction.GOAL_TOPUP]: goalInvestmentDetails?.currency,
    [InvestmentAction.GOAL_INVESTMENT]: goalInvestmentDetails?.currency,
  };

  const amountPerOperations = {
    [InvestmentAction.DIRECT_INVESTMENT]: planInvestmentDtls?.amount,
    [InvestmentAction.DIRECT_INVESTMENT_TOPUP]: planInvestmentDtls?.amount,
    [InvestmentAction.POCKET_TOPUP]: pocketTopupDetails?.amount,
    [InvestmentAction.GOAL_TOPUP]: goalInvestmentDetails?.amount,
    [InvestmentAction.GOAL_INVESTMENT]: goalInvestmentDetails?.amount,
  };

  const renderPaymentOptions = () => {
    if (payWithMpesa) {
      return (
        <PayWithMobileMoney
          setPayWithMobileMoney={setPayWithMpesa}
          amount={amountPerOperations[investmentAction]}
          promoCode={planInvestmentDtls?.promoCode}
          currency={currencyPerOperations[investmentAction]}
          selectedCountry={getCountryDetailsPerCurrency(
            currencyPerOperations[investmentAction],
          )}
          setOpen={() => {}}
          discountAmount={discount?.amount}
        />
      );
    } else if (payWithBankTransfer) {
      return (
        <PayWithBankTransfer
          amount={amountPerOperations[investmentAction]}
          currency={currencyPerOperations[investmentAction]}
          promoCode={planInvestmentDtls?.promoCode}
          setPayWithBankTransfer={setPayWithBankTransfer}
          planDetails={reduxPlanInvestmentDtls?.viewedPlan}
          activePaymentMethod={activePaymentMethod}
          discountAmount={discount?.amount}
        />
      );
    } else if (payWithBongaPoints) {
      return (
        <PayWithBongaPoints
          totalAmount={totalAmount}
          discount={discount}
          amount={amountPerOperations[investmentAction]}
          currency={currencyPerOperations[investmentAction]}
          promoCode={planInvestmentDtls?.promoCode}
          setpayWithBongaPoints={setpayWithBongaPoints}
          minimumInvestableAmount={
            reduxPlanInvestmentDtls?.viewedPlan?.minInvestableAmounts?.KES
          }
        />
      );
    } else if (useVoucher) {
      return (
        <ApplyVoucher
          alertOn={alertOn}
          setAlertOn={setAlertOn}
          alert={alert}
          formik={formik}
          getInputClasses={getInputClasses}
          investmentDetails={planInvestmentDtls}
          handleChange={handleChange}
          setUseVoucher={setUseVoucher}
          applyPromoCode={applyPromoCode}
          loading={loading}
        />
      );
    } else {
      return (
        <InvestmentPaymentDetailsPage
          payWithCreditCard={payWithCreditCard}
          setUseVoucher={setUseVoucher}
          loading={loading}
          discount={discount}
          amount={amountPerOperations[investmentAction]}
          currency={currencyPerOperations[investmentAction]}
          subscription={subscription}
          planDetails={reduxPlanInvestmentDtls?.viewedPlan}
          totalAmount={totalAmount}
          payWithMpesa={payWithMpesa}
          setPayWithMpesa={setPayWithMpesa}
          payWithBankTransfer={payWithBankTransfer}
          setPayWithBankTransfer={setPayWithBankTransfer}
          setpayWithBongaPoints={setpayWithBongaPoints}
          setAlert={setAlert}
          setAlertOn={setAlertOn}
          alert={alert}
          alertOn={alertOn}
          activePaymentMethod={activePaymentMethod}
          setActivePaymentMethod={setActivePaymentMethod}
          alertRef={alertRef}
        />
      );
    }
  };

  return (
    <AnimatedPageContainer>
      <Back text="Back" onClick={() => history.goBack()} />
      {renderPaymentOptions()}
    </AnimatedPageContainer>
  );
};

export default PlanInvestmentPayment;
