import React from 'react';
import { ListCheckBox } from '../../Common/ListCheckBox';

export const PlanFeature = ({ children, title, boxBackground, boxColor }) => {
  return (
    <li>
      <div className="d-flex align-items-start mt-2">
        <ListCheckBox background={boxBackground} color={boxColor} />
        <span className="text-11 ml-1" style={{ marginTop: '4px' }}>
          {title}
        </span>
      </div>

      {children ? children : null}
    </li>
  );
};
