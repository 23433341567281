import React from 'react';

export const HowItWorksModalContent = () => {
  return (
    <div>
      <div className="text-18 font-weight-600 text-dark-blue-800 mb-7">
        Why Choose Ndovu?
      </div>
      <di className="text-16">
        Ndovu is a trusted and regulated investment platform licensed by the
        Capital Markets Authority (CMA) in Kenya. Your funds are safeguarded in
        a Custodian Bank, ensuring the highest level of security and
        accessibility for your investments. Rest assured, your assets remain
        protected under all circumstances.
      </di>
    </div>
  );
};
