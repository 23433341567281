import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { actions as investorProfileActions } from '../../Profile/_redux/profileRedux';

import { InvestorAccountType } from '../Components/InvestorAccountType';
import { AccountTypesList, accountTypes } from '../../../helpers/constants';
import ExternalPageTitle from '../../Common/ExternalPageTitle/ExternalPageTitle';

const AccountType = () => {
  const history = useHistory();
  // TODO::Handle errors

  const dispatch = useDispatch();
  const profile = useSelector(state => state.profile);
  const accessToken = localStorage.getItem('ndovuToken');

  const selectPrimaryAccount = async value => {
    localStorage.setItem('accountType', value);

    if (value === accountTypes.INDIVIDUAL) {
      if (profile?.user?.hasSignedInWithGoogle) {
        localStorage.setItem('ndovuToken', profile.accessToken || accessToken);

        delete profile.accessToken;
        delete profile.idToken;

        dispatch(investorProfileActions.profileAction(profile));
      }

      history.push('/home');
    } else {
      history.push('/onboarding/accountDetails');
    }
  };

  return (
    <>
      <div className="login-form">
        <ExternalPageTitle title="Select account Type" />

        <div>
          {AccountTypesList.map(account => (
            <InvestorAccountType
              account={account}
              key={account?.value}
              selectPrimaryAccount={selectPrimaryAccount}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default AccountType;
