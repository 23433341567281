import React from 'react';
import { useHistory } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import { useSelector } from 'react-redux';

import { CustomButton } from '../../../Common/ReusableButtons';
import { handleKycRouting } from '../../../../helpers/handleKycRouting';
import PopoverIcon from '../../../Common/PopoverIcon';

export const TimelineItem = ({
  title,
  description,
  competed,
  informationIconName,
  iconName,
  addButton,
  buttonText,
  descriptionTextColor,
  iconOpensChatWidget,
}) => {
  const userProfile = useSelector(state => state.profile);
  const history = useHistory();

  const { investor } = userProfile;
  const { kycLevel, kycStatus, pendingKyc, investorType } = investor;

  const kycChecks = { kycLevel: kycLevel?.level, kycStatus, pendingKyc };

  const openChatWidget = () => {
    if (window.$zoho && window.$zoho.salesiq) {
      window.$zoho.salesiq.floatwindow.visible('show');
    } else {
      console.log('Zoho SalesIQ is not initialized yet.');
    }
  };
  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center mr-2">
          <div>
            <SVG src={`/media/svg/ndovu/${iconName}.svg`} />
          </div>

          <div className="d-flex flex-column ml-5">
            <div className="text-13 font-weight-600">{title}</div>
            <div className={`text-11 ${descriptionTextColor}`}>
              {description}
            </div>
          </div>
        </div>
        {informationIconName ? (
          iconOpensChatWidget ? (
            <div className="cursor-pointer" onClick={openChatWidget}>
              <SVG src={`/media/svg/ndovu/${informationIconName}.svg`} />
            </div>
          ) : (
            <div className="cursor-pointer">
              <PopoverIcon
                message={
                  <span>
                    All funds and investments are held in a{' '}
                    <span className="font-weight-600">Custodian Bank</span>,
                    ensuring your assets stay safe. Investment reconciliation
                    involves verifying and matching your payment details with
                    our records to ensure your funds are correctly allocated.
                  </span>
                }
                iconName={informationIconName}
              />
            </div>
          )
        ) : addButton ? (
          <CustomButton
            buttonText={buttonText}
            classes={
              'd-flex justify-content-center align-items-center bg-orange-90 orange-button-hover text-dark-gray-550 border-primary-orange border-radius-16 height-35'
            }
            handleOnClick={() =>
              history.push(
                handleKycRouting(kycChecks, '/manage-account', investorType),
              )
            }
            buttonWidth={'width-190'}
            addBorder
          />
        ) : null}
      </div>

      <div
        className={`timeline-vertical-line ${
          competed ? 'bg-second-green' : 'bg-white-200'
        }`}
      ></div>
    </>
  );
};
