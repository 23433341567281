import React from 'react';

export const Button = ({
  buttonText,
  handleButtonClick,
  loading,
  disabled,
}) => {
  return (
    <button
      type="button"
      className={`${
        disabled
          ? 'bg-gray-550 text-white'
          : 'bg-light-blue-540 text-blue cursor-pointer'
      } d-block mx-auto border-0 payment-button text-14 font-weight-500 mb-4 border-radius-4 py-2`}
      onClick={handleButtonClick}
      disabled={disabled}
    >
      {buttonText} {loading && <span className="ml-3 spinner spinner-white" />}
    </button>
  );
};
