import React from 'react';
import SVG from 'react-inlinesvg';

import { capitalizeTheFirstLetterOfEachWord } from '../../../helpers/investmentHelpers';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';

export const ConfirmButton = ({
  buttonText,
  classes,
  handleOnClick,
  buttonType,
  disable,
  loading,
  buttonWidth,
  changeText = true,
  buttonIcon,
  twinButton,
}) => {
  return (
    <button
      onClick={handleOnClick}
      className={`${classes} ${
        buttonWidth
          ? buttonWidth
          : twinButton
          ? 'twin-button-width'
          : 'button-width'
      } ${
        disable ? 'bg-gray-550' : 'bg-blue blue-button-hover cursor-pointer'
      } border-radius-4 text-13 font-weight-500 height-40 border-0 text-white`}
      type={buttonType ? buttonType : 'button'}
      disabled={disable}
    >
      <div className="d-flex justify-content-center align-items-center">
        {buttonIcon ? <SVG src={toAbsoluteUrl(buttonIcon)} /> : null}
        <span className="font-weight-700 ml-2">
          {typeof buttonText === 'string'
            ? changeText && buttonText !== 'OK'
              ? capitalizeTheFirstLetterOfEachWord(buttonText)
              : buttonText
            : buttonText}{' '}
          {loading && <span className="ml-3 spinner spinner-white" />}
        </span>
      </div>
    </button>
  );
};

export const CancelButton = ({
  buttonText,
  classes,
  handleOnClick,
  buttonType,
  disable,
  loading,
  buttonWidth,
  twinButton,
  buttonIcon,
}) => {
  return (
    <button
      onClick={handleOnClick}
      className={`${classes} ${
        buttonWidth
          ? buttonWidth
          : twinButton
          ? 'twin-button-width'
          : 'button-width'
      } bg-white border-radius-4 text-14 height-40 cursor-pointer border-1-primary-blue text-blue`}
      type={buttonType ? buttonType : 'button'}
      disabled={disable}
    >
      <div className="d-flex justify-content-center align-items-center">
        {buttonIcon ? <SVG src={toAbsoluteUrl(buttonIcon)} /> : null}
        <span className="font-weight-700 ml-2">
          {typeof buttonText === 'string'
            ? capitalizeTheFirstLetterOfEachWord(buttonText)
            : buttonText}{' '}
          {loading && <span className="ml-3 spinner spinner-white" />}
        </span>
      </div>
    </button>
  );
};

export const CustomButton = ({
  buttonText,
  classes,
  handleOnClick,
  buttonType,
  disable,
  loading,
  buttonWidth,
  buttonIcon,
  addBorder = false,
  spinnerColor,
}) => {
  return (
    <button
      onClick={handleOnClick}
      className={`height-40 ${disable ? 'bg-gray-550 text-white' : classes} ${
        buttonWidth ? buttonWidth : 'button-width'
      } ${!addBorder && 'border-0'} border-radius-4 text-14 cursor-pointer`}
      type={buttonType ? buttonType : 'button'}
      disabled={disable}
    >
      <div className="d-flex justify-content-center align-items-center">
        {buttonIcon ? (
          <div className="mr-2">
            <SVG src={toAbsoluteUrl(buttonIcon)} />
          </div>
        ) : null}
        <span className="font-weight-500 text-13">
          {typeof buttonText === 'string'
            ? capitalizeTheFirstLetterOfEachWord(buttonText)
            : buttonText}{' '}
          {loading && (
            <span
              className={`ml-3 spinner ${
                spinnerColor ? spinnerColor : 'spinner-white'
              }`}
            />
          )}
        </span>
      </div>
    </button>
  );
};

export const TextButton = ({ handleButtonClick, buttonText }) => {
  return (
    <span
      className="text-decoration-underline text-13 font-weight-600 text-gray-440 mx-auto mt-3 cursor-pointer"
      onClick={handleButtonClick}
    >
      {buttonText}
    </span>
  );
};
