import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import SVG from 'react-inlinesvg';

import { actions as goalReducerActions } from '../../Goal/_redux/goalReducers';
import { actions as pocketActions } from '../../Pocket/_redux/reducers';
import { actions as investorProfileActions } from '../../Profile/_redux/profileRedux';
import { actions as investmentReducerActions } from '../../Invest/_redux/investmentReducers';

import CurrencyOptions from '../../Common/CurrencyOptions';
import { changePreferedCurrency } from '../../Profile/_redux/profileCrud';
import Back from '../../Common/BackArrow';
import { PreferenceContainer } from '../components/PreferenceContainer';
import { PreferenceLeftContent } from '../components/PreferenceLeftContent';
import Alert from '../../Common/Alert';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import { FullWidthWrapper } from '../../Common/FullWidthWrapper';

const Preferences = () => {
  const history = useHistory();
  const {
    profile,
    currencies: { currencies },
  } = useSelector(state => state);

  const { investor } = profile;

  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentCurrency, setCurrentCurrency] = useState(
    investor?.currency?.currency,
  );

  const dispatch = useDispatch();

  const reduxGoalInvestmentDtls = useSelector(
    state => state?.goal?.goalInvestmentDetails,
  );

  const handleCurrencyChange = async event => {
    setLoading(true);
    const selectedCurrency = currencies?.find(
      currency => currency.currency === event.target.value,
    );

    try {
      const {
        data: { data },
      } = await changePreferedCurrency(selectedCurrency?.id);
      setCurrentCurrency(data?.currency?.currency);

      reduxGoalInvestmentDtls?.staticGoalId &&
        dispatch(
          goalReducerActions.updateGoalInvestmentDetails({
            ...reduxGoalInvestmentDtls,
            currency: data?.currency?.currency,
          }),
        );

      dispatch(
        investorProfileActions.profileAction({
          ...profile,
          investor: { ...investor, currency: data?.currency },
          loading: false,
        }),
      );

      selectedCurrency?.pockets[0]
        ? dispatch(
            pocketActions.updatePocket({
              ...selectedCurrency?.pockets[0],
              currency: selectedCurrency?.currency,
            }),
          )
        : dispatch(pocketActions.updatePocket(null));

      setLoading(false);
    } catch (error) {
      setErrorMessage(error.response.data.message);
      setLoading(false);
    }
  };

  const handleTakeQuizButton = () => {
    dispatch(investmentReducerActions.resetInvestmentAction());

    history.push('/wizard/age');
  };

  return (
    <div className="mt-5">
      <Back title={'Preferences'} />
      <FullWidthWrapper>
        {
          <Alert
            alert={{ alertMessage: errorMessage, alertMessageType: 'error' }}
          />
        }
        {/* Investor Type */}
        <PreferenceContainer>
          <PreferenceLeftContent
            label={'Investor Type'}
            value={investor?.riskProfile?.alias}
          />

          <div
            className="custom-btn px-2"
            type="submit"
            onClick={handleTakeQuizButton}
          >
            {investor?.riskProfile?.alias
              ? 'Change Investor Type'
              : 'Take Quiz'}
            <SVG
              style={{ paddingLeft: '3px' }}
              src={toAbsoluteUrl('/media/svg/ndovu/arrow-white.svg')}
            ></SVG>
          </div>
        </PreferenceContainer>

        {/* User Currency */}
        <PreferenceContainer>
          {loading ? (
            <span className="ml-3 spinner spinner-white" />
          ) : (
            <PreferenceLeftContent
              label={'User Currency'}
              value={currentCurrency}
            />
          )}

          <select
            placeholder="Currency"
            className="goal-amount-currency"
            id="exampleSelect1"
            onChange={handleCurrencyChange}
            defaultValue={investor?.currency?.currency}
          >
            <option value="">Currency</option>
            <CurrencyOptions />
          </select>
        </PreferenceContainer>
      </FullWidthWrapper>
    </div>
  );
};

export default Preferences;
