import { pocketActionsEnum } from '../../../helpers/constants';
import { addCommas } from '../../../helpers/handleInput';

export const displayWarningOnWithdrawal = (
  pocketBalance,
  selectedCurrency,
  operation,
  setWarningMessage,
  setDisableAmountInput,
  alertInitialState,
) => {
  const roundAmountToTwoDecimalNumbers = false;
  const withdrawing = operation === pocketActionsEnum.WITHDRAW;
  let errorMessage = null;

  if (
    withdrawing &&
    pocketBalance < selectedCurrency?.minimumWithdrawableAmount
  ) {
    errorMessage =
      pocketBalance === 0
        ? `You have 0 ${selectedCurrency?.currency} on your pocket`
        : pocketBalance < selectedCurrency?.minimumWithdrawableAmount &&
          `You can only withdraw ${selectedCurrency?.currency} ${addCommas(
            pocketBalance,
            roundAmountToTwoDecimalNumbers,
          )}`;

    setWarningMessage({
      alertMessage: errorMessage,
    });

    setDisableAmountInput(true);
  } else {
    setWarningMessage(alertInitialState);
    setDisableAmountInput(false);
  }

  return errorMessage;
};
