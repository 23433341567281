import React, { Suspense, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Switch, useHistory } from 'react-router-dom';
import { ContentRoute, LayoutSplashScreen } from '../_metronic/layout';
import InvestorType from './modules/Account/components/InvestorType';
import ReferralPromo from './modules/Account/components/ReferralPromo';
import SecurityPage from './modules/Account/components/Security/index';
import BusinessManagement from './modules/Business/pages/index';
import Dashboard from './modules/Dashboard/pages/dashboard';
import Goal from './modules/Goal';
import Goals from './modules/Goal/pages/Goals';
import UploadKYC from './modules/Individual/pages';
import Invest from './modules/Invest';
import FinalPage from './modules/Invest/components/FinalPage';
import AccountManagement from './modules/Management/pages/index';
import Plan from './modules/Plan';
import ManageAccount from './modules/Pocket/pages';
import Portfolio from './modules/Portfolio/index';
import ProfileOverview from './modules/Profile/pages/profileOverview';
import Settlement from './modules/Settlements/pages/index';
import Congratulations from './modules/Subscriptions/Congratulations';
import Subscribe from './modules/Subscriptions/Subscribe';
import Subscriptions from './modules/Subscriptions/Subscriptions';
import Transaction from './modules/Transaction/pages/Transaction';
import WatchList from './modules/WatchList/pages';
import SettlementWithdrawalDetails from './modules/WithdrawalDetails/';
import WizardHomePage from './modules/Wizard/pages/wizardHomePage';
import { BuilderPage } from './pages/BuilderPage';
import { ErrorPage } from './pages/ErrorPage';
import SessionTimeout from './utils/sessionTimeout';
import useUserProfile from './helpers/CustomHook/useUserProfile';
import RecurringPayments from './modules/RecurringPayments';
import { trackEventMixpanel } from './eventTracking/mixpanel';
import useObserveAndDetectClick from './helpers/CustomHook/useElementObserver';
import AdditionalInformation from './modules/Onboarding/Pages/AdditionalInformation';

const BasePage = () => {
  const profile = useSelector(state => state?.profile);
  const { investor } = profile;
  const originPartner = investor?.originPartner;

  const { updateInvestorProfile } = useUserProfile();

  const history = useHistory();

  const restrictURls = () => {
    const locationUrl = window.location?.pathname;
    if (!originPartner) {
      return '';
    }
    const { REACT_APP_ORIGIN_PARTNER_PLAN_ID } = process.env;

    if (
      locationUrl === '/new-investment' ||
      locationUrl === '/plans' ||
      locationUrl === '/plans/fixed-return' ||
      locationUrl?.includes('goal')
    ) {
      history.push(`/plans/fixed-return/${REACT_APP_ORIGIN_PARTNER_PLAN_ID}`);
    }
  };

  useEffect(() => {
    updateInvestorProfile();
  }, [updateInvestorProfile]);

  const handleClick = () => {
    trackEventMixpanel('Help_Button_Click');
  };

  useObserveAndDetectClick('zsiq_float', handleClick);

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <SessionTimeout isAuthenticated={true} />
      <Switch>
        {restrictURls()}
        <Redirect exact from="/" to="/home" />
        <ContentRoute path="/home" component={Dashboard} />
        <ContentRoute path="/settings" component={ProfileOverview} />
        <ContentRoute
          path="/security"
          render={props => <SecurityPage profile={profile} {...props} />}
        />
        <ContentRoute
          path="/referral-promo"
          component={() => <ReferralPromo profile={profile} />}
        />
        <ContentRoute path="/investor-type" component={InvestorType} />
        <ContentRoute path="/subscription" component={Subscriptions} />
        <ContentRoute path="/subscribe" component={Subscribe} />
        <ContentRoute path="/congratulations" component={Congratulations} />
        <ContentRoute path="/new-investment" component={Invest} />{' '}
        {/* TODO: To be removed */}
        <ContentRoute
          path="/goal"
          component={investor?.riskProfile === null ? WizardHomePage : Goal}
        />
        <ContentRoute path="/plans" component={Plan} />
        <ContentRoute path="/portfolio" component={Portfolio} />
        <ContentRoute path="/goals" component={Goals} />
        <ContentRoute path="/transaction" component={Transaction} />
        <ContentRoute path="/watchlist" component={WatchList} />
        <ContentRoute
          path="/manage-account"
          component={props => (
            <AccountManagement profile={profile} {...props} />
          )}
        />
        <ContentRoute
          path="/investor-information"
          component={props => (
            <BusinessManagement profile={profile} {...props} />
          )}
        />
        <ContentRoute
          path="/kyc-information"
          component={props => <UploadKYC profile={profile} {...props} />}
        />
        <ContentRoute path="/payment-status" component={FinalPage} />
        <ContentRoute path="/settlement" component={Settlement} />
        <ContentRoute path="/pocket" component={ManageAccount} />
        <ContentRoute path="/builder" component={BuilderPage} />
        <ContentRoute
          path="/withdrawal-details"
          component={SettlementWithdrawalDetails}
        />
        <ContentRoute
          path="/recurring-payments"
          component={RecurringPayments}
        />
        <ContentRoute path="/error-page" component={ErrorPage} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
};

export default BasePage;
