import React from 'react';
import './input.scss';

export default function Input({
  placeholder,
  name,
  type,
  formik,
  className,
  onChange,
  value,
}) {
  const handleChange = e => {
    if (onChange) {
      onChange(e);
    } else if (formik) {
      formik.setFieldValue(name, e.target.value);
    }
  };
  return (
    <div>
      <input
        placeholder={placeholder}
        type={type}
        className={`${className} border-gray-450 border-radius-8`}
        name={name}
        {...formik?.getFieldProps(`${name}`)}
        autoComplete="off"
        value={value || formik?.values[name]}
        onChange={handleChange}
      />
    </div>
  );
}
