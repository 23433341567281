import './ExternalLeft/index.scss';

import React from 'react';
import { routes } from '../../utils/routes';
import { accountTypes } from '../../helpers/constants';

const DisplayAppropriateContent = () => {
  let pathName = window.location.pathname;
  const accountType = localStorage.getItem('accountType');
  const isChamaAccount =
    accountType?.toLocaleLowerCase() === accountTypes.CHAMA.toLocaleLowerCase();

  if (
    pathName === routes.wizard.home ||
    pathName === `${routes.wizard.home}/`
  ) {
    return (
      <p className="content-style mr-10">
        One size doesn’t fit all,{' '}
        <span className="font-weight-bold" style={{ color: '#0071CE' }}>
          the same applies to your investment portfolio...
        </span>
      </p>
    );
  } else if (pathName === routes.wizard.age) {
    return (
      <p className="content-style mr-10">
        The younger you are, the bolder your{' '}
        <span className="font-weight-bold" style={{ color: '#0071CE' }}>
          financial decisions...
        </span>
      </p>
    );
  } else if (pathName === routes.wizard.purpose) {
    return (
      <p className="content-style mr-10">
        Life has{' '}
        <span className="font-weight-bold" style={{ color: '#0071CE' }}>
          priorities
        </span>
        , which one is yours...
      </p>
    );
  } else if (pathName === routes.wizard.monthlyIncome) {
    return (
      <p className="content-style mr-10">
        Everything from your J.O.B{' '}
        <span className="font-weight-bold" style={{ color: '#0071CE' }}>
          to your side hustle...
        </span>
      </p>
    );
  } else if (pathName === routes.wizard.monthlySavings) {
    return (
      <p className="content-style mr-10">
        You should be saving{' '}
        <span className="font-weight-bold" style={{ color: '#0071CE' }}>
          20% of your income...
        </span>
      </p>
    );
  } else if (pathName === routes.wizard.cashSavings) {
    return (
      <p className="content-style mr-10">
        Job loss,
        <span className="font-weight-bold" style={{ color: '#0071CE' }}>
          {` pandemic`}
        </span>
        , illness...
      </p>
    );
  } else if (pathName === routes.wizard.timeFrame) {
    return (
      <p className="content-style mr-10">
        It's not about timing the market,{' '}
        <span className="font-weight-bold" style={{ color: '#0071CE' }}>
          it's about time in the market...
        </span>
      </p>
    );
  } else if (pathName === routes.wizard.investmentExperience) {
    return (
      <p className="content-style mr-10">
        Experience or no experience,{' '}
        <span className="font-weight-bold" style={{ color: '#0071CE' }}>
          ndovu gets you started...
        </span>
      </p>
    );
  } else if (pathName === routes.wizard.familiarInvestments) {
    return (
      <p className="content-style mr-10">
        If you invest & don't diversify, you have{' '}
        <span className="font-weight-bold" style={{ color: '#0071CE' }}>
          all your eggs in one basket...
        </span>
      </p>
    );
  } else if (pathName === routes.wizard.investmentAttitude) {
    return (
      <p className="content-style mr-10">
        Informed risk, <br />
        <span className="font-weight-bold" style={{ color: '#0071CE' }}>
          {`is good risk...`}
        </span>
      </p>
    );
  } else if (pathName === routes.wizard.tenPercentInvestmentLoss) {
    return (
      <p className="content-style mr-10">
        Investments go up &amp; down, but{' '}
        <span className="font-weight-bold" style={{ color: '#0071CE' }}>
          appreciate in the long term...
        </span>
      </p>
    );
  } else if (pathName === routes.wizard.personalDetails) {
    return (
      <p className="content-style mr-10">
        Almost done!
        <br />
        <span className="font-weight-bold" style={{ color: '#0071CE' }}>
          Create your account...
        </span>
      </p>
    );
  } else if (
    pathName.match(routes.wizard.login) ||
    pathName.match(routes.wizard.register) ||
    pathName.match(routes.wizard.selectSignUpOption) ||
    pathName.match(routes.wizard.googleCallback) ||
    pathName.match(routes.wizard.additionalInformation)
  ) {
    return (
      <p className="content-style mr-10">
        Welcome!
        <br />
        Let's build the{' '}
        <span className="font-weight-bold" style={{ color: '#0071CE' }}>
          life you want...
        </span>
      </p>
    );
  } else if (pathName === routes.wizard.forgotPassword) {
    return (
      <p className="content-style mr-10">
        It's wise to{' '}
        <span className="font-weight-bold" style={{ color: '#0071CE' }}>
          change your <br /> password often...
        </span>
      </p>
    );
  } else if (pathName === routes.wizard.resendEmailVerification) {
    return (
      <p className="content-style mr-10">
        Safety is key, <br />
        <span className="font-weight-bold" style={{ color: '#0071CE' }}>
          let's verify your email
        </span>
      </p>
    );
  } else if (pathName === routes.wizard.resetPassword) {
    return (
      <p className="content-style mr-10">
        Forgotten your password, <br />
        <span className="font-weight-bold" style={{ color: '#0071CE' }}>
          let's fix it...
        </span>
      </p>
    );
  } else if (pathName === routes.wizard.selectAccountType) {
    return (
      <p className="content-style mr-10" style={{ color: '#0071CE' }}>
        Select Account Type
      </p>
    );
  } else if (pathName === routes.wizard.selectedAccountType) {
    if (isChamaAccount) {
      return (
        <p className="content-style mr-10" style={{ color: '#0071CE' }}>
          Chama Investor Details
        </p>
      );
    } else
      return (
        <p className="content-style mr-10" style={{ color: '#0071CE' }}>
          Business Investor Details
        </p>
      );
  } else {
    return <div />;
  }
};

export default DisplayAppropriateContent;
