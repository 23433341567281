import React from 'react';

export const PreferenceLeftContent = ({ label, value }) => {
  return (
    <div className="d-flex flex-column auth-left text-bold-blue">
      <span className="text-17 font-weight-500 text-dark-blue-700">
        {label}
      </span>
      <span className="text-12 font-weight-500 text-dark-blue-700">
        {value}
      </span>
    </div>
  );
};
