import React from 'react';

export const CardWithHeader = ({ cardHeader, children }) => {
  return (
    <div
      id="card-margin"
      className="card mx-md-30 mx-0 border-light-blue-600 border-radius-8"
    >
      <div className="text-dark-700 font-weight-600 text-16 text-center py-6">
        {cardHeader}
      </div>

      <div
        style={{
          width: '100%',
          borderBottom: '1px solid #94C3EA',
        }}
      />
      {children}
    </div>
  );
};
