import React from 'react';

export const ListCheckBox = ({ background, color }) => {
  return (
    <div>
      <svg
        width="14"
        height="13"
        viewBox="0 0 14 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.97765 0.200684C10.4102 0.200684 13.1906 2.98115 13.1906 6.41366C13.1906 9.84616 10.4102 12.6266 6.97765 12.6266C3.54514 12.6266 0.764676 9.84616 0.764676 6.41366C0.754541 2.99128 3.52149 0.210819 6.94387 0.200684C6.954 0.200684 6.96751 0.200684 6.97765 0.200684Z"
          fill={background ? background : 'white'}
        />
        <path
          d="M10.4214 4.70037L6.00916 9.11263L3.53613 6.65988L4.54291 5.67337L6.00916 7.11935L9.41464 3.71387L10.4214 4.70037Z"
          fill={color ? color : '#faa36c'}
        />
      </svg>
    </div>
  );
};
