import React from 'react';
import SVG from 'react-inlinesvg';
import { currencyHelper } from '../../../../helpers/currencyHelper';
import { operationType as OperationType } from '../../../../helpers/constants';

export const TransactionHeader = ({ amount, currency, operationType }) => {
  const isDeposit = operationType === OperationType.DEPOSIT;

  return (
    <div className="d-flex justify-content-between mb-7">
      <div className="d-flex align-items-center text-14">
        <SVG
          src={`/media/svg/ndovu/${
            isDeposit ? 'invest-blue' : 'disinvest-blue'
          }.svg`}
        />

        <div className="ml-5 font-weight-600">
          {isDeposit ? 'Investment' : 'Disinvestment'}
        </div>
      </div>

      <div
        className={`${
          isDeposit ? 'text-blue' : 'text-primary-orange'
        } font-weight-500`}
      >
        {isDeposit ? '+' : '-'}
        {currencyHelper(amount, currency)}
      </div>
    </div>
  );
};
